import React from 'react'
import AdminForgotPassword from "../components/Shop/AdminForgotPassword";

const ShopForgotPassword = () => {
 
  return (
    <div>
        <AdminForgotPassword />
    </div>
  )
}

export default ShopForgotPassword;